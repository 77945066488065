import React, { Component } from "react"
import AsyncSelect from "react-select/lib/Async";
import { request } from "../../../api-client"
import styled from 'styled-components';
import { makeFitImageUrlWithSize } from '../../../utils';
import { get } from 'lodash';
import FaceFilters from '../../../components/FaceFilters';
import ContentCreationPlayer from "../../../components/Letflow/ContentCreationPlayer";

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  width: 100%;
  padding: 16px;
`;

const PhotoCard = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: transform 0.2s;
  
  &:hover {
    transform: scale(1.02);
  }
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

class GridContentCreations extends Component {
    constructor(props) {  
      super(props)
      this.state = {
        contentCreations: [],
        client: null,
        loading: true,
        selectedPhoto: null,
        searchPerson: null,
        filters: [],
        genderFilter: null,
        ageRangeFilter: null,
        emotionFilter: null,
        facialFeaturesFilter: null,
        showContentCreationDialog: false,
        currentPlayingContent: {}
      }
    } 

    componentDidMount = () => {
      let selectedClientFromLocalStorage = JSON.parse(localStorage.getItem("selectedclient"));
  
      if (selectedClientFromLocalStorage) {
        this.setState({client: selectedClientFromLocalStorage}, () => {
          this.requestContentCreations()
        });
      }
    }
    

    requestContentCreations = () => {
      return request.people.getAllPhotosForClient(this.state.client.value)
            .then(contentCreations => this.setState({ contentCreations, loading: false }))
    }
    
    getFilteredContentCreations = () => {
      const { 
        contentCreations, 
        searchPerson,
        genderFilter,
        ageRangeFilter,
        emotionFilter,
        facialFeaturesFilter 
      } = this.state;

      let filtered = contentCreations;

      if (searchPerson) {
        filtered = filtered.filter(contentCreation => contentCreation.person_id === searchPerson.value);
      }

      if (get(genderFilter, 'value')) {
        filtered = filtered.filter(contentCreation => 
          get(contentCreation, 'face_metadata.Gender.Value', '').toLowerCase() === genderFilter.value
        );
      }

      if (get(ageRangeFilter, 'value')) {
        const [min, max] = ageRangeFilter.value.split('-');
        filtered = filtered.filter(contentCreation => {
          const age = get(contentCreation, 'face_metadata.AgeRange.Value');
          if (max) {
            return age >= parseInt(min) && age <= parseInt(max);
          }
          return age >= parseInt(min);
        });
      }

      if (get(emotionFilter, 'value')) {
        filtered = filtered.filter(contentCreation => 
          get(contentCreation, 'face_metadata.Emotions', [])
            .some(emotion => emotion.Type.toLowerCase() === emotionFilter.value && emotion.Confidence > 50)
        );
      }

      if (get(facialFeaturesFilter, 'value')) {
        const [feature, value] = facialFeaturesFilter.value.split(':');
        filtered = filtered.filter(contentCreation => 
          get(contentCreation, `face_metadata.${feature}.Value`, false) === (value === 'true')
        );
      }

      return filtered;
    }

    handleFiltersChange = (filterParams) => {
      request.people.getAllPhotosForClient(this.state.client.value, filterParams)
        .then(contentCreations => {
          this.setState({ contentCreations });
        });
    }

    render() {
      const { 
        client, 
        loading,
      } = this.state;

      const clientId = client && client.value;

      return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px'}}>
          <ContentCreationPlayer
            show={this.state.showContentCreationDialog}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.setState({showContentCreationDialog: false, currentPlayingContent: {}})}
            dashboard
          />
          <div style={{width: "100%", maxWidth: 300}}>
            <span>Cliente</span>
            <AsyncSelect
              value={client}
              onChange={client => {
                localStorage.setItem("selectedclient", JSON.stringify(client));
                this.setState({client: null}, () => 
                  this.setState({client}, this.requestContentCreations)
                );
              }}
              loadOptions={inputValue => {
                let filters = [{column: "name", filter: inputValue}]
                return request.client.getAll({filterBy: filters}, '')
                  .then(res => res.map(client => ({
                    value: client.id, 
                    label: client.name
                  })))
              }}
              placeholder="Seleccionar cliente"
            />
          </div>
          {clientId && (
            <FaceFilters 
              clientId={clientId}
              showPersonSearch={true}
              loadPersonOptions={this.loadPersonOptions}
              onFiltersChange={this.handleFiltersChange}
            />
          )}
          {!loading && (
            <PhotoGrid>
              {this.getFilteredContentCreations().map(contentCreation => (
                <PhotoCard key={contentCreation.id} onClick={() => this.setState({ showContentCreationDialog: true, currentPlayingContent: contentCreation})}>
                  <PhotoImage 
                    src={makeFitImageUrlWithSize(contentCreation, 'md')} 
                    alt=""
                  />
                </PhotoCard>
              ))}
            </PhotoGrid>
          )}
        </div>
      )
    }
}

export default GridContentCreations;
