import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from "react-select/lib/Async";
import { get } from 'lodash';
import localization from '../../config/localization';
import styled from 'styled-components';
import { request } from '../../api-client';

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const FilterGroup = styled.div`
  width: 200px;
`;

class FaceFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      loading: true,
      searchPerson: null,
      genderFilter: null,
      ageRangeFilter: null,
      emotionFilter: null,
      facialFeaturesFilter: null
    };
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.requestFilters();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId && this.props.clientId) {
      this.requestFilters();
    }
  }

  requestFilters = () => {
    this.setState({ loading: true });
    request.people.getFilters(this.props.clientId)
      .then(response => {
        this.setState({
          filters: response,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error loading filters:', error);
        this.setState({ loading: false });
      });
  }

  buildFilterParams = () => {
    const { 
      searchPerson,
      genderFilter,
      ageRangeFilter,
      emotionFilter,
      facialFeaturesFilter 
    } = this.state;

    const params = {};

    if (searchPerson && searchPerson.value) {
      params.person_id = searchPerson.value;
    }

    if (genderFilter && genderFilter.value) {
      params.gender = genderFilter.value;
    }

    if (ageRangeFilter && ageRangeFilter.value) {
      const [min, max] = ageRangeFilter.value.split('-');
      params['age_range[min]'] = parseInt(min);
      if (max) {
        params['age_range[max]'] = parseInt(max);
      }
    }

    if (emotionFilter && emotionFilter.value) {
      params.emotion = emotionFilter.value;
    }

    if (facialFeaturesFilter && facialFeaturesFilter.value) {
      const [feature, value] = facialFeaturesFilter.value.split(':');
      params[`facial_features[${feature}]`] = value === 'true' ? 'true' : '!true';
    }

    return params;
  }

  handleFilterChange = (filterName, value) => {
    this.setState({ [filterName]: value }, () => {
      const filterParams = this.buildFilterParams();

      this.props.onFiltersChange(filterParams);
    });
  }

  getGenderOptions = () => {
    const genderFilters = get(this.state.filters, 'filters.gender', {});
    return [
      { value: null, label: localization.get('rekognition.gender.all') },
      ...Object.entries(genderFilters).map(([key, value]) => ({
        value: key,
        label: localization.get(`rekognition.gender.${value}`)
      }))
    ];
  }

  getEmotionOptions = () => {
    const emotionFilters = get(this.state.filters, 'filters.emotions', {});
    return [
      { value: null, label: localization.get('rekognition.emotion.all') },
      ...Object.entries(emotionFilters).map(([key, value]) => ({
        value: key,
        label: localization.get(`rekognition.emotion.${value}`)
      }))
    ];
  }

  getFacialFeaturesOptions = () => {
    const features = get(this.state.filters, 'filters.facial_features', {});
    return [
      { value: null, label: localization.get('rekognition.facial_features.all') },
      ...Object.entries(features).map(([feature, data]) => [
        {
          value: `${feature}:true`,
          label: localization.get(`rekognition.facial_features.${data.options.true}`)
        },
        {
          value: `${feature}:false`,
          label: localization.get(`rekognition.facial_features.${data.options.false}`)
        }
      ]).flat()
    ];
  }

  getAgeRangeOptions = () => {
    const ageRange = get(this.state.filters, 'filters.age_range', { min: 0, max: 100 });
    const ranges = [
      [0, 12],
      [13, 20],
      [21, 30],
      [31, 50],
      [51, ageRange.max]
    ];

    return [
      { value: null, label: 'Todas las edades' },
      ...ranges.map(([min, max]) => ({
        value: `${min}-${max}`,
        label: max === ageRange.max ? `${min}+` : `${min}-${max}`
      }))
    ];
  }

  render() {
    const { 
      loading, 
      filters,
      searchPerson,
      genderFilter,
      ageRangeFilter,
      emotionFilter,
      facialFeaturesFilter
    } = this.state;

    const { showPersonSearch, loadPersonOptions } = this.props;

    if (loading || !filters) {
      return <div>{localization.get('loading')}</div>;
    }

    return (
      <FiltersContainer>
        {showPersonSearch && (
          <FilterGroup style={{flex: 1, minWidth: '200px'}}>
            <span>{localization.get('rekognition.search_person')}</span>
            <AsyncSelect
              value={searchPerson}
              onChange={value => this.handleFilterChange('searchPerson', value)}
              loadOptions={inputValue => {
                return request.people.getAllForClient(this.props.clientId, 
                    {filterBy: [
                        {column: "name", filter: inputValue},
                        {column: "use_name", filter: 1}
                    ]})
                  .then(people => people.map(person => ({
                    value: person.id,
                    label: person.name
                  })))
              }}
              placeholder={localization.get('rekognition.search_by_name')}
              defaultOptions
              isClearable
            />
          </FilterGroup>
        )}

        <FilterGroup>
          <span>{localization.get('rekognition.gender')}</span>
          <Select
            value={genderFilter}
            onChange={value => this.handleFilterChange('genderFilter', value)}
            options={this.getGenderOptions()}
            isClearable
            isDisabled={loading}
          />
        </FilterGroup>

        <FilterGroup>
          <span>{localization.get('rekognition.age_range')}</span>
          <Select
            value={ageRangeFilter}
            onChange={value => this.handleFilterChange('ageRangeFilter', value)}
            options={this.getAgeRangeOptions()}
            isClearable
            isDisabled={loading}
          />
        </FilterGroup>

        <FilterGroup>
          <span>{localization.get('rekognition.emotion')}</span>
          <Select
            value={emotionFilter}
            onChange={value => this.handleFilterChange('emotionFilter', value)}
            options={this.getEmotionOptions()}
            isClearable
            isDisabled={loading}
          />
        </FilterGroup>

        <FilterGroup>
          <span>{localization.get('rekognition.facial_features')}</span>
          <Select
            value={facialFeaturesFilter}
            onChange={value => this.handleFilterChange('facialFeaturesFilter', value)}
            options={this.getFacialFeaturesOptions()}
            isClearable
            isDisabled={loading}
          />
        </FilterGroup>
      </FiltersContainer>
    );
  }
}

export default FaceFilters; 